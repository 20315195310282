import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function MaadCityPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-name-filter="maad city" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/maad-city-logo.png"
      backgroundSrc="/hero/maad-city-hero.jpg"
      imageMaxWidth="322px"
      title="MAAD CITY"
      description={
        <>
          <p>
            We bring the maaddest and the baaddest Hip Hop & RNB raave to the
            baasement of HiFi Club every Tuesday. Our City was built from a
            maassive love of all things Hip Hop and wanting to create an
            intimate paarty energised by the sounds of Hip Hop, RNB, Dancehall
            and Bashment. For the past decaade, Tuesdays have been HiFi and
            we're about to level them up!
          </p>
          <p>
            Our lights to the City are now on, be sure to join the population!
          </p>
        </>
      }
    />
  );
}
